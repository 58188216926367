import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { useOutsideClick } from '../../shared/hooks'

const Container = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  min-width: 240px;
  height: 32px;
  padding: 5px 14px;
  margin-bottom: 32px;
  border: none;
  border-radius: 8px;
  background: white;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.06);
  white-space: nowrap;

  svg {
    width: 22px;
    height: 22px;
    margin-right: 6px;
  }

  .toggle {
    width: 100%;
    padding: 6px 18px;
    margin: -6px -18px;
    text-align: left;
    font-family: inherit;
    font-size: 13px;
    font-weight: 400;
    box-sizing: content-box;
  }

  .dropdown {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    padding: 8px;
    background: white;
    box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    z-index: 10;

    .search-container {
      position: relative;
    }

    .search-input {
      width: 100%;
      padding: 4px 4px 4px 30px;
      border: 1px solid var(--input-border);
      border-radius: 3px;
    }

    .search-clear {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      color: var(--text-secondary);

      svg {
        width: 12px;
        height: 12px;
        margin: 0;
      }
    }

    .search-icon {
      position: absolute;
      top: 6px;
      left: 9px;
      width: 18px;
      height: 18px;
    }

    .search-results {
      padding-bottom: 8px;
      max-height: 200px;
      overflow: auto;
    }

    .search-label {
      padding: 0 2px;
      margin-top: 12px;
      color: var(--text-secondary);
      font-size: 12px;
    }

    .search-variants {
    }

    .search-variant {
      display: block;
      width: 100%;
      padding: 2px 4px;
      margin: 1px 0px;
      border-radius: 2px;
      text-align: left;
      background: transparent;
      transition: background 0.3s;

      &:hover {
        background: var(--text-background-hover);
      }
    }

    .search-no-results {
      margin-top: 16px;
      color: var(--text-secondary);
      text-align: center;
      font-size: 12px;
      font-weight: bold;
    }
  }

  svg.arrow {
    position: absolute;
    top: 7px;
    right: 8px;
    width: 14px;
    height: 18px;
    color: black;
    pointer-events: none;
  }
`

const defaultSourceUuid = process.env.PUBLISHER_UUID
const defaultSourceType = 'publisher'

export const useSourcePickerState = () => {
  const [sourceUuid, setSourceUuid] = useState(defaultSourceUuid)
  const [sourceType, setSourceType] = useState(defaultSourceType)
  const [sourceName, setSourceName] = useState('')
  const [sourceContracts, setSourceContracts] = useState(null)

  return {
    sourceUuid,
    setSourceUuid,
    sourceType,
    setSourceType,
    sourceName,
    setSourceName,
    sourceContracts,
    setSourceContracts,
  }
}

export const SourcePicker = (props) => {
  const { state, onChange } = props

  const {
    sourceContracts,
    sourceUuid,
    setSourceUuid,
    setSourceType,
    sourceName,
    setSourceName,
  } = state

  const [open, setOpen] = useState(false)
  const containerRef = useRef()
  useOutsideClick(containerRef, () => setOpen(false))

  const [searchValue, setSearchValue] = useState(sourceName)

  const setSource = (type, uuid, name) => () => {
    setSourceUuid(uuid || defaultSourceUuid)
    setSourceType(type || defaultSourceType)
    setSourceName(name)
    setSearchValue(name)
    onChange()
    setOpen(false)
  }

  const normalizedSearchValue = searchValue.trim().toLowerCase()
  const shownContracts = (sourceContracts || []).filter(
    (contract) =>
      contract.code.toLowerCase().indexOf(normalizedSearchValue) !== -1
  )

  return (
    <Container ref={containerRef}>
      <button type="button" className="a toggle" onClick={() => setOpen(!open)}>
        {sourceName || 'All sources'}
      </button>
      <SVG src="/images/icons/arrow-down.svg" width={14} className="arrow" />
      {open && (
        <div className="dropdown">
          <div className="search-container">
            <input
              className="search-input"
              placeholder="Search"
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
            />
            <button
              className="a search-clear"
              hidden={!searchValue}
              onClick={() => {
                setSearchValue('')
              }}
            >
              <SVG src="/images/icons/cancel.svg" />
            </button>
            <SVG src="/images/icons/search.svg" className="search-icon" />
          </div>

          <div className="search-results">
            {!!sourceUuid && sourceUuid !== defaultSourceUuid && (
              <div className="search-variants">
                <button
                  type="button"
                  className="a search-variant mt-3"
                  onClick={setSource('', '', '')}
                >
                  Show all sources
                </button>
              </div>
            )}

            {shownContracts?.length > 0 && (
              <>
                <div className="search-label">Campaigns</div>
                <div className="search-variants">
                  {shownContracts.map((contract) => (
                    <button
                      key={contract.code}
                      type="button"
                      className="a search-variant"
                      onClick={setSource(
                        'contract',
                        contract.uuid,
                        contract.code
                      )}
                    >
                      {contract.code}
                    </button>
                  ))}
                </div>
              </>
            )}

            {searchValue && !shownContracts?.length && (
              <div className="search-no-results">Nothing found</div>
            )}
          </div>
        </div>
      )}
    </Container>
  )
}
